import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carousel", "nextButton", "prevButton"];

  prev() {
    this.carouselTarget.swiper.slidePrev();
  }

  next() {
    this.carouselTarget.swiper.slideNext();
  }
}
